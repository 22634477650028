import './styles.index.scss';

import axios from 'axios';
import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import jwtDecode from 'jwt-decode';

import {
  AccountInfo,
  Configuration,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import {
  MsalAuthenticationResult,
  MsalAuthenticationTemplate,
  MsalProvider,
  useMsal,
} from '@azure/msal-react';

import * as serviceWorker from './serviceWorker';
import App from './App';
import { acquireAccessToken, initConfig } from './api/general';
import { logger } from './logger';

const queryClient = new QueryClient();

function AuthStatusScreen({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return <div className="auth__screen">{children}</div>;
}

function AuthError({
  error,
}: React.PropsWithChildren<MsalAuthenticationResult>): JSX.Element {
  return (
    <AuthStatusScreen>
      <div className="auth__error-title">An Error Occurred</div>

      {error?.errorMessage ? (
        <div className="auth__error-message">{error.errorMessage}</div>
      ) : null}
    </AuthStatusScreen>
  );
}

function AuthLoading(): JSX.Element {
  return (
    <AuthStatusScreen>
      <div>Loading</div>
    </AuthStatusScreen>
  );
}

export type IAuthContext = {
  account: AccountInfo;
  roles: string[];
};

export const AuthContext = React.createContext<IAuthContext>({
  account: {
    name: '',
    username: '',
    tenantId: '',
    environment: '',
    homeAccountId: '',
    idTokenClaims: {},
    localAccountId: '',
  },
  roles: [],
});

axios
  .get('/config.json')
  .then((res) => {
    const config = res.data;
    const authConfiguration: Configuration = {
      auth: {
        redirectUri: '/login',
        clientId: config.REACT_APP_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${config.REACT_APP_TENANT_ID}`,
      },
      cache: {
        cacheLocation: 'localStorage',
      },
    };

    const authInstance = new PublicClientApplication(authConfiguration);
    authInstance.initialize();

    initConfig.set(config);

    function AuthProvider({
      children,
    }: {
      children: React.ReactNode;
    }): JSX.Element {
      const { instance, accounts } = useMsal();
      const [roles, setRoles] = React.useState<string[]>([]);

      const initialSetting = async (): Promise<void> => {
        const accessToken = await acquireAccessToken(instance, accounts);
        const jwt: any = jwtDecode(accessToken);
        setRoles(jwt.roles);
      };

      React.useEffect(() => {
        try {
          initialSetting();
        } catch (error) {
          if (axios.isAxiosError(error)) {
            logger.log(
              'Error message in fetching access token: ',
              error.message,
            );
          } else {
            logger.log('Unexpected error in fetching access token: ', error);
          }
        }
      }, []);

      return (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <AuthContext.Provider value={{ account: accounts[0], roles }}>
          {children}
        </AuthContext.Provider>
      );
    }

    const root = ReactDOM.createRoot(
      document.getElementById('root') as HTMLElement,
    );
    root.render(
      <MsalProvider instance={authInstance}>
        <MsalAuthenticationTemplate
          errorComponent={AuthError}
          loadingComponent={AuthLoading}
          interactionType={InteractionType.Redirect}>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <App />
            </AuthProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </MsalAuthenticationTemplate>
      </MsalProvider>,
    );

    serviceWorker.unregister();
  })
  .catch();
