import React, { useContext, useEffect, useState } from 'react';

import {
  CButton,
  CForm,
  CFormInput,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CTable,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilCheckCircle, cilBan } from '@coreui/icons';
import { AuthContext } from '../..';
import { ROLE_ADMIN } from '../../utils/constants';

const columns = [
  {
    key: 'field',
    _props: { scope: 'col' },
  },
  {
    key: 'value',
    _props: { scope: 'col' },
  },
];

// Types
type cardProps = {
  subReq: { key: string; value: string }[];
  isSubProcessed: boolean;
  approveSubReq: () => Promise<void>;
  rejectSubReq: () => Promise<void>;
  isModalVisible: boolean;
  setIsModalVisible: (modalVisibility: boolean) => void;
  handleRejection: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isOwnerOrApprover: boolean;
  isButtonPressed: boolean;
};

// Main function: Subscription Request Card
function SubscriptionRequestCard({
  subReq,
  isSubProcessed,
  approveSubReq,
  rejectSubReq,
  isModalVisible,
  setIsModalVisible,
  handleRejection,
  isOwnerOrApprover,
  isButtonPressed,
}: cardProps): JSX.Element {
  const [items, setItems] = useState([]);
  const { roles } = useContext(AuthContext);

  const prepareTable = (subReqs: { key: string; value: string }[]): void => {
    const tableItems: any = [];
    subReqs.map((req: { key: string; value: string }) => {
      tableItems.push({
        field: req.key,
        value: req.value,
      });
      return req.key;
    });
    setItems(tableItems);
  };

  const disableViewRights = (): boolean => {
    return !(roles.includes(ROLE_ADMIN) || isOwnerOrApprover);
  };

  useEffect(() => {
    prepareTable(subReq);
  }, [subReq]);

  return (
    <div className="page-body">
      <CTable
        bordered
        className="subscription-requests-table"
        tableHeadProps={{ color: 'dark' }}
        columns={columns}
        items={items}
      />
      {isButtonPressed && !isSubProcessed && (
        <>
          <div className="button-container">
            <CButton
              className="button"
              color="success"
              onClick={() => {
                approveSubReq();
              }}
              disabled={disableViewRights()}>
              <CIcon icon={cilCheckCircle} />
              <span> Approve</span>
            </CButton>
            <CButton
              className="button"
              color="danger"
              onClick={() => {
                setIsModalVisible(!isModalVisible);
              }}
              disabled={disableViewRights()}>
              <CIcon icon={cilBan} />
              <span> Reject</span>
            </CButton>
          </div>
          <CModal
            visible={isModalVisible}
            onClose={() => setIsModalVisible(false)}>
            <CModalHeader>
              <CModalTitle>Rejection Reason</CModalTitle>
            </CModalHeader>
            <CModalBody>
              <CForm>
                <CFormInput
                  type="text"
                  onChange={handleRejection}
                  id="rejectionReason"
                  label="Write your reason for rejecting the subscription request"
                  placeholder="Rejection reason here..."
                  aria-describedby="subscription-request-rejection-reason-box"
                />
              </CForm>
            </CModalBody>
            <CModalFooter>
              <CButton
                color="secondary"
                onClick={() => setIsModalVisible(false)}>
                Close
              </CButton>
              <CButton color="danger" onClick={rejectSubReq}>
                Reject
              </CButton>
            </CModalFooter>
          </CModal>
        </>
      )}
    </div>
  );
}

export default SubscriptionRequestCard;
