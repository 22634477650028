import { IPublicClientApplication, AccountInfo } from '@azure/msal-browser';
import { sendHttpRequest } from './general';

export const fetchAllSubscriptionRequests = async (
  instance: IPublicClientApplication,
  accounts: AccountInfo[],
): Promise<Record<string, any> | null> => {
  const subscriptionRequests = await sendHttpRequest(
    instance,
    accounts,
    'aam-subscriptions/?state=submitted',
    'GET',
  );
  return subscriptionRequests;
};

export const fetchSubscriptionRequestById = (
  instance: IPublicClientApplication,
  accounts: AccountInfo[],
  requestId: string | undefined,
): Promise<Record<string, string> | null> =>
  sendHttpRequest(
    instance,
    accounts,
    `subscription-review/${requestId}/`,
    'GET',
  );

export const fetchAPIInfo = (
  instance: IPublicClientApplication,
  accounts: AccountInfo[],
  productName: string,
): Promise<Record<string, string> | null> =>
  sendHttpRequest(instance, accounts, `apis/${productName}/`, 'GET');

export const approveSubscriptionRequest = async (
  instance: IPublicClientApplication,
  accounts: AccountInfo[],
  requestId: string | undefined,
): Promise<string> => {
  const response = await sendHttpRequest(
    instance,
    accounts,
    `subscription-review/${requestId}/approve/`,
    'POST',
  );
  return response?.Success[0];
};

export const rejectSubscriptionRequest = async (
  instance: IPublicClientApplication,
  accounts: AccountInfo[],
  requestId: string | undefined,
  bodyContent: any,
): Promise<string> => {
  const response = await sendHttpRequest(
    instance,
    accounts,
    `subscription-review/${requestId}/reject/`,
    'POST',
    bodyContent,
  );
  return response?.Success[0];
};
