import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import '@coreui/coreui/dist/css/coreui.min.css';

import Home from './components/Home';
import SubscriptionRequestDetail from './components/subscription-request/SubscriptionRequestDetail';
import SubscriptionRequests from './components/subscription-request/SubscriptionRequests';
import FriendlyHelperHomePage from './components/friendly-helper/FriendlyHelperHomePage';
import NavBar from './components/NavBar';
import EmailNotification from './components/email-notifications/EmailNotificationChecklist';

function App(): JSX.Element {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="subscription-request/:requestId"
          element={<SubscriptionRequestDetail />}
        />
        <Route
          path="subscription-requests"
          element={<SubscriptionRequests />}
        />
        <Route path="email-notifications" element={<EmailNotification />} />
        <Route path="aad-request-helper" element={<FriendlyHelperHomePage />} />
      </Routes>
    </Router>
  );
}

export default App;
