import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { InteractionRequiredAuthError } from '@azure/msal-common';
import axios from 'axios';

class APIConfig {
  appId: string;

  baseUrl: string;

  env: string;

  employeeID: string;

  set(config: Record<string, string>): void {
    this.appId = config.API_APP_ID;
    this.baseUrl = config.API_BASE_URL;
    this.env = config.REACT_APP_ENVIRONMENT;
    this.employeeID = config.EMPLOYEE_API_ID;
  }
}

export const initConfig = new APIConfig();

/**
 * Retrieves an access token. Validates or retrieves (if necessary) an access token by using Microsoft Authenticator React library
 * @returns {Promise<string[]>}
 */
export const acquireAccessToken = async (
  instance: IPublicClientApplication,
  accounts: AccountInfo[],
): Promise<string | null> => {
  try {
    const response = await instance.acquireTokenSilent({
      account: accounts[0],
      scopes: [`${initConfig.appId}/.default`],
    });
    return response.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      await instance.acquireTokenRedirect({
        account: accounts[0],
        scopes: [`${initConfig.appId}/.default`],
      });
    }
    return null;
  }
};

/**
 * A general function for sending any type of HTTP(S) request.
 * Sends an HTTP(S) request to the endpoint with the method and returns the response.
 * @param {string} endpoint - The URL in which the request will be sent
 * @param {string} method - Type of methods such as GET, POST, DELETE
 * @returns {Promise<Record<string, string> | null>}
 */
export const sendHttpRequest = async (
  instance: IPublicClientApplication,
  accounts: AccountInfo[],
  endpoint: string,
  method: string,
  bodyContent?: Record<string, string>,
): Promise<Record<string, any> | null> => {
  const accessToken = await acquireAccessToken(instance, accounts);
  const { data } = await axios.request({
    url: `${initConfig.baseUrl}/${endpoint}`,
    method,
    params: false,
    data: bodyContent,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return data;
};
