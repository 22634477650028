/* eslint-disable @typescript-eslint/ban-ts-comment */

import { useEffect, useState } from 'react';
import { CDropdown, CDropdownMenu, CDropdownToggle } from '@coreui/react';
import { Permission } from './aadRequestType';

function PermissionSelection({
  permissionList,
  permissionClickHandler,
  displayPermissionList,
  refreshPermissionList,
  dropdownTitle,
}: {
  permissionList: Permission[];
  permissionClickHandler: any;
  displayPermissionList: boolean;
  refreshPermissionList: boolean;
  dropdownTitle: string;
}): JSX.Element {
  const [permissions, setPermissions] = useState<Permission[]>([]);

  useEffect(() => {
    if (refreshPermissionList) {
      setPermissions([]);
    }
  }, [refreshPermissionList]);

  const handleCheckBox = (permissionOption: Permission): void => {
    let returnPermissions: Permission[] = [];
    if (
        'role_id' in permissionOption &&
        permissions.find(
            // @ts-ignore
            (permission) => permission.role_id === permissionOption.role_id,
        )
    ) {
      returnPermissions = permissions.filter(
          // @ts-ignore
          (permission) => permission.role_id !== permissionOption.role_id,
      );
    } else if (
        'scope_id' in permissionOption &&
        permissions.find(
            // @ts-ignore
            (permission) => permission.scope_id === permissionOption.scope_id,
        )
    ) {
      returnPermissions = permissions.filter(
          // @ts-ignore
          (permission) => permission.scope_id !== permissionOption.scope_id,
      );
    } else {
      returnPermissions = [...permissions, permissionOption];
    }
    setPermissions(returnPermissions);
    permissionClickHandler('permission', returnPermissions);
  };

  const isChecked = (permissionValue: string): boolean => {
    return !!permissions.find(
        (permission) => permission.value === permissionValue,
    );
  };

  return (
      <CDropdown variant="input-group" autoClose="outside">
        <CDropdownToggle color="secondary" variant="outline">
          {displayPermissionList && permissions.length > 0
              ? `${permissions.length} selected`
              : dropdownTitle}
        </CDropdownToggle>
        <CDropdownMenu className="role-dropdown">
          {displayPermissionList ? (
              permissionList.map((permissionOption) => (
                  <div key={permissionOption.value} className="form-check">
                    <input
                        type="checkbox"
                        value={permissionOption.value}
                        id={permissionOption.value}
                        checked={isChecked(permissionOption.value)}
                        onChange={() => handleCheckBox(permissionOption)}
                        className="form-check-input me-2"
                        data-testid={`checkbox-${permissionOption.value}`}
                    />
                    <label className="form-check-label">
                      {permissionOption.value}
                    </label>
                  </div>
              ))
          ) : (
              <p data-testid="no-permissions" className="p-2 m-0 text-body-secondary">
                No {dropdownTitle.toLowerCase()}s available
              </p>
          )}
        </CDropdownMenu>
      </CDropdown>
  );
}

export default PermissionSelection;