import { initConfig } from './api/general';

/** Signature of a logging function */
export interface LogFn {
  (message?: any, ...optionalParams: any[]): void;
}

/** Basic logger interface */
export interface Logger {
  log: LogFn;
  warn: LogFn;
  error: LogFn;
}

/** Log levels */
export type LogLevel = 'log' | 'warn' | 'error';

/** Logger which outputs to the browser console */
export class ConsoleLogger implements Logger {
  readonly log: LogFn;

  readonly warn: LogFn;

  readonly error: LogFn;

  constructor(options?: { level?: LogLevel }) {
    const { level } = options || {};

    this.error = console.error.bind(console);
    this.warn = console.warn.bind(console);

    if (level === 'log') {
      this.log = console.log.bind(console);
    } else {
      const noOperation: LogFn = () => undefined;
      this.log = noOperation;
    }
  }
}

const LOG_LEVEL: LogLevel = initConfig.env === 'prod' ? 'warn' : 'log';

export const logger = new ConsoleLogger({ level: LOG_LEVEL });
