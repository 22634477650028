import {
  CFormCheck
} from '@coreui/react';


type checkboxProps = {
  id: string;
  title: string;
  subscribed: boolean;
  type: string;
  handleChange: (id: string) => void;
};

function EmailNotificationCheckbox({
  id,
  title,
  subscribed,
  type,
  handleChange,
}: checkboxProps): JSX.Element {

  return (
        <div>
        <CFormCheck key={id} id={String(id)} label={title} checked={subscribed} disabled={type === 'required'} onChange={() => handleChange('')}/>
        </div>
  );
}

export default EmailNotificationCheckbox;
