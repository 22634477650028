import { cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CButton,
} from '@coreui/react';
import { InitAadRequest } from './aadRequestType';

const convertEnvName = {
  dev: 'Dev',
  qa: 'QA',
  prod: 'Prod',
};

function RequestsTable({
  aadRequestList,
  removeAadRequest,
}: {
  aadRequestList: Array<InitAadRequest>;
  removeAadRequest: any;
}): JSX.Element {
  return (
    <div className="d-flex flex-column mt-3">
      <h3>List of permissions to be requested</h3>
      {aadRequestList.length > 0 ? (
        <CTable hover>
          <CTableHead color="light">
            <CTableRow>
              <CTableHeaderCell>Application</CTableHeaderCell>
              <CTableHeaderCell>API</CTableHeaderCell>
              <CTableHeaderCell>Environment</CTableHeaderCell>
              <CTableHeaderCell>Roles</CTableHeaderCell>
              <CTableHeaderCell />
            </CTableRow>
          </CTableHead>
          <CTableBody>
            {aadRequestList.map((aadItem) => (
            <CTableRow key={aadItem.api.id}>
                <CTableDataCell>
                  {aadItem.application.display_name}
                  <div className="mt-2">
                    <a
                      href={`https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/CallAnAPI/appId/${aadItem.application.app_id}`}
                      target="blank">
                      View existing API permissions
                    </a>
                  </div>
                </CTableDataCell>
                <CTableDataCell>
                  {aadItem.api.display_name}
                  <div className="mt-2">
                    <a
                      href={`https://developers.wartsila.com/api-details#api=${aadItem.api.id}`}
                      target="blank">
                      View API Details
                    </a>
                  </div>
                </CTableDataCell>
                <CTableDataCell>
                  {convertEnvName[aadItem.registration.environment]}
                </CTableDataCell>
                <CTableDataCell>
                  {Array.from(aadItem.roles.map((role) => role.value)).join(
                    ', ',
                  )}
                </CTableDataCell>
                <CTableDataCell>
                  <CButton
                    color="danger"
                    onClick={() => removeAadRequest(aadItem)}
                    className="text-nowrap">
                    Remove <CIcon icon={cilTrash} size="custom" />
                  </CButton>
                </CTableDataCell>
              </CTableRow>
            ))}
          </CTableBody>
        </CTable>
      ) : (
        <p className="fs-5 fw-lighter fst-italic">No data available</p>
      )}
    </div>
  );
}

export default RequestsTable;
