import axios from 'axios';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { useMsal } from '@azure/msal-react';
import { CAlert, CSpinner } from '@coreui/react';

import SubscriptionRequestCard from './SubscriptionRequestCard';
import { logger } from '../../logger';

import {
  approveSubscriptionRequest,
  fetchAPIInfo,
  fetchSubscriptionRequestById,
  rejectSubscriptionRequest,
} from '../../api/subscriptionRequest';
import { AuthContext } from '../..';
import { ROLE_ADMIN } from '../../utils/constants';

export default function SubscriptionRequestDetail(): JSX.Element {
  const { instance, accounts } = useMsal();
  const [rejectionReason, setRejectionReason] = React.useState('');
  const [subscriptionRequest, setSubscriptionRequest] = React.useState<
    { key: string; value: string }[]
  >([]);
  const [isSubscriptionProcessed, setIsSubscriptionProcessed] =
    React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const { requestId } = useParams();
  const [modalVisible, setModalVisible] = React.useState(false);
  const [blankRejectionReasonVisible, setBlankRejectionReasonVisible] =
    React.useState(false);
  const [successfulRejectionVisible, setSuccessfulRejectionVisible] =
    React.useState(false);
  const [successfulApprovalVisible, setSuccessfulApprovalVisible] =
    React.useState(false);
  const [isSubscriptionRequestFetched, setIsSubscriptionRequestFetched] =
    React.useState(false);
  const [responseFromRejection, setResponseFromRejection] = React.useState('');
  const [responseFromApproval, setResponseFromApproval] = React.useState('');
  const [ownerOrApprover, setOwnerOrApprover] = React.useState(false);
  const [isButtonPressed, setButtonPressed] = React.useState(true);
  const { roles } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [errorMessageVisibility, setErrorMessageVisibility] =
    React.useState(false);

  /**
   * Handles the rejection reason for a subscription request. Changes the setRejectionReason.
   * @param {React.ChangeEvent<HTMLInputElement>} event - The event that keeps track of the input field.
   * You do not have to give that input parameter since it's already provided by default.
   */
  const handleRejectionReason = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { value } = event.target;
    setRejectionReason(value);
  };

  /**
   * Handles the approval of a subscription request. Sends a request to approve a request and makes necessary arrangements in the UI if it succeeds
   * @returns {Promise<void>}
   */
  const handleApproval = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const data = await approveSubscriptionRequest(
        instance,
        accounts,
        requestId,
      );
      setIsLoading(false);
      setIsSubscriptionRequestFetched(true);
      if (data != null) {
        setResponseFromApproval(data);
        setModalVisible(false);
        setSuccessfulApprovalVisible(true);
        setIsSubscriptionProcessed(true);
        setButtonPressed(false);
      }
    } catch (error) {
      setIsLoading(false);
      setModalVisible(false);
      setErrorMessageVisibility(true);
      setErrorMessage('Unexpected error');
      if (axios.isAxiosError(error)) {
        // Axios Error
        if (error.response) {
          // An example of error.message: Request failed with status code 404
          logger.log('Axios Error Message:', error.message);
          logger.log('Axios Error Detail:', error.response.statusText);
        }
      } else {
        // Any other error
        logger.log(
          'Unexpected error while approving a subscription request:',
          error,
        );
      }
    }
  };

  /**
   * Handles the rejection of a subscription request.
   * Sends a request to reject a request and makes necessary arrangements in the UI if rejectionReason meets the requirements and request succeeds
   * @returns {Promise<void>}
   */
  const handleRejection = async (): Promise<void> => {
    // Check the followings:
    // 1. reason is not null
    // 2. reason is not empty
    // 3. reason does not contain only whitespace
    if (rejectionReason === null || rejectionReason.trim().length === 0) {
      setModalVisible(false);
      setBlankRejectionReasonVisible(true);
    } else {
      const bodyContent: any = { state_comment: rejectionReason };
      try {
        setIsLoading(true);
        const data = await rejectSubscriptionRequest(
          instance,
          accounts,
          requestId,
          bodyContent,
        );
        setIsLoading(false);
        setIsSubscriptionRequestFetched(true);
        if (data != null) {
          setResponseFromRejection(data);
          setModalVisible(false);
          setSuccessfulRejectionVisible(true);
          setIsSubscriptionProcessed(true);
          setButtonPressed(false);
        }
      } catch (error) {
        setIsLoading(false);
        setModalVisible(false);
        setErrorMessageVisibility(true);
        setErrorMessage('Unexpected error');
        if (axios.isAxiosError(error)) {
          // Axios error
          if (error.response) {
            logger.log('Axios Error Message:', error.message);
            logger.log('Axios Error Detail:', error.response.statusText);
          }
        } else {
          // Any other error
          logger.log(
            'Unexpected error while rejecting a subscription request:',
            error,
          );
        }
      }
    }
  };

  /**
   * Prepares a subscription request to be displayed.
   * Sets the fields of a subscription request to be displayed from the request and api parameters. Sets reviewDate, reviewer and reviewStatus fields according to request state as well.
   * @param {any} request - The subscription request information.
   * @param {any} api - The API information.
   * @returns {{ key: string; value: string }[]}
   */
  const prepareSubscriptionRequestToBeDisplayed = (
    request: any,
    api: any,
  ): { key: string; value: string }[] => {
    const subscriptionRequestInfoToBeDisplayed: {
      key: string;
      value: string;
    }[] = [
      { key: 'API Name', value: request.product_name },
      { key: 'Request Date', value: request.subscribed_at },
      { key: 'Requester', value: request.user.aam_email },
      { key: 'Subscription Name', value: request.name },
      { key: 'Environment', value: request.env.toUpperCase() },
      { key: 'API Owner', value: api.owner.email },
      {
        key: 'API Approvers',
        value: api.approvers
          .map((approver: Record<string, string>) => approver.email)
          .join(' - '),
      },
      {
        key: 'Subscription State',
        value: request.state.charAt(0).toUpperCase() + request.state.slice(1),
      },
    ];

    if (request.state !== 'submitted') {
      subscriptionRequestInfoToBeDisplayed.push({
        key: 'Review Date',
        value: request.reviewed_at,
      });
      subscriptionRequestInfoToBeDisplayed.push({
        key: 'Reviewer',
        value: request.review_user,
      });

      if (request.state === 'rejected') {
        subscriptionRequestInfoToBeDisplayed.push({
          key: 'Rejection Reason',
          value: request.state_comment,
        });
      }
      setIsSubscriptionProcessed(true);
    }
    return subscriptionRequestInfoToBeDisplayed;
  };

  const checkApiOwnerOrApprover = (apiInfo: any): void => {
    const currentUserEmail = accounts[0].username;
    if (apiInfo.owner.email === currentUserEmail) {
      setOwnerOrApprover(true);
    }

    apiInfo.approvers.forEach((approver: Record<string, string>) => {
      if (approver.email === currentUserEmail) {
        setOwnerOrApprover(true);
      }
    });
  };

  /**
   * Fetches a subscription by sending two consecutive HTTP requests to the endpoints subscription-review/{request_id} and apis/{product_name}, respectively.
   * @returns {Promise<void>}
   */
  const fetchSubscriptionRequest = async (): Promise<void> => {
    try {
      const subscriptionRequestInfo: Record<string, string> | null =
        await fetchSubscriptionRequestById(instance, accounts, requestId);

      if (subscriptionRequestInfo !== null) {
        const apiInfo: Record<string, string> | null = await fetchAPIInfo(
          instance,
          accounts,
          subscriptionRequestInfo.product_name,
        );
        setIsSubscriptionRequestFetched(true);
        checkApiOwnerOrApprover(apiInfo);
        const subscriptionRequestToBeDisplayed =
          prepareSubscriptionRequestToBeDisplayed(
            subscriptionRequestInfo,
            apiInfo,
          );
        setSubscriptionRequest(subscriptionRequestToBeDisplayed);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessageVisibility(true);
      if (axios.isAxiosError(error)) {
        // Axios error
        if (error.response?.status === 404) {
          setErrorMessage(
            'No such subscription request or you are not authorized to view it',
          );
          logger.log('Axios Error Message:', error.message);
          logger.log('Axios Error Detail:', error.response.statusText);
        } else {
          setErrorMessage('Unexpected error');
          logger.log('Axios Error:', error.message);
        }
      } else {
        // Any other error
        setErrorMessage('Unexpected error');
        logger.log(
          'Unexpected error while fetching a subscription request:',
          error,
        );
      }
    }
  };

  React.useEffect(() => {
    fetchSubscriptionRequest();
  }, [isSubscriptionProcessed]);

  return (
    <div className="page-body">
      {/* Alert messages start here */}
      <CAlert
        color="danger"
        variant="solid"
        dismissible
        visible={blankRejectionReasonVisible}
        onClose={() => setBlankRejectionReasonVisible(false)}>
        The rejection reason field cannot be left blank!
      </CAlert>

      <CAlert
        color="info"
        variant="solid"
        dismissible
        visible={successfulRejectionVisible}
        onClose={() => setSuccessfulRejectionVisible(false)}>
        {responseFromRejection}
      </CAlert>

      <CAlert
        color="success"
        variant="solid"
        dismissible
        visible={successfulApprovalVisible}
        onClose={() => setSuccessfulApprovalVisible(false)}>
        {responseFromApproval}
      </CAlert>

      <CAlert
        color="danger"
        variant="solid"
        dismissible
        visible={errorMessageVisibility}
        onClose={() => setErrorMessageVisibility(false)}>
        {errorMessage}
      </CAlert>
      {/* Alert messages end here */}

      <h1 className="text-center">Subscription Approval/Rejection</h1>

      {isLoading ? (
        <CSpinner className="spinner" />
      ) : (
        <div>
          {isSubscriptionRequestFetched &&
          (roles.includes(ROLE_ADMIN) || ownerOrApprover) ? (
            <SubscriptionRequestCard
              subReq={subscriptionRequest}
              isSubProcessed={isSubscriptionProcessed}
              approveSubReq={handleApproval}
              rejectSubReq={handleRejection}
              isModalVisible={modalVisible}
              setIsModalVisible={setModalVisible}
              handleRejection={handleRejectionReason}
              isOwnerOrApprover={ownerOrApprover}
              isButtonPressed={isButtonPressed}
            />
          ) : null}
        </div>
      )}
    </div>
  );
}
