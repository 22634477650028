import axios from 'axios';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

import { CSpinner, CTable, CAlert } from '@coreui/react';

import { logger } from '../../logger';
import { fetchAllSubscriptionRequests } from '../../api/subscriptionRequest';
import { AuthContext } from '../..';
import './subscriptionRequest.scss';
import { ROLE_ADMIN } from '../../utils/constants';

export default function SubscriptionRequests(): JSX.Element {
  const { instance, accounts } = useMsal();
  const [isLoading, setIsLoading] = React.useState(true);
  const [items, setItems] = React.useState([]);
  const { roles } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [errorMessageVisibility, setErrorMessageVisibility] =
    React.useState(false);

  const columns = [
    {
      key: 'id',
      label: '#',
      _props: { scope: 'col' },
    },
    {
      key: 'name',
      _props: { scope: 'col' },
    },
    {
      key: 'state',
      _props: { scope: 'col' },
    },
  ];

  /**
   * Prepares the data and related columns for the Subscription Requests table
   * @param {Record<string, any> | null} subscriptionRequests - The subscription requests whose state is submitted
   * @returns {<void>}
   */
  const prepareTable = (
    subscriptionRequests: Record<string, any> | null,
  ): void => {
    let counter = 1;
    const tableItems: any = [];

    subscriptionRequests!.results.map((element: any) => {
      const parts = element.url.split('/');
      const subscriptionRequestId = parts[parts.length - 2];
      tableItems.push({
        id: counter,
        name: (
          <Link to={`/subscription-request/${subscriptionRequestId}`}>
            {element.name}
          </Link>
        ),
        state: element.state,
        _cellProps: { id: { scope: 'row' } },
      });
      counter += 1;
      return element.name;
    });
    setItems(tableItems);
  };

  /**
   * Fetches a subscription by sending two consecutive HTTP requests to the endpoints subscription-review/{request_id} and apis/{product_name}, respectively.
   * @returns {Promise<void>}
   */
  const fetchSubscriptionRequests = async (): Promise<void> => {
    // Quick fix: when the Subscription Requests page is refreshed, we get
    // an empty array for the roles list. The reason is that the Context API
    // does not persist data across page reloads.
    // In the future, we have two options to fix this issue:
    // 1. Store the data on the server and make necessary API calls
    // 2. Store the data within browser storage (local storage, session storage, etc.)
    if (!roles.includes(ROLE_ADMIN) && roles.length > 0) {
      setIsLoading(false);
      setErrorMessage('You do not have necessary rights to view this page');
      setErrorMessageVisibility(true);
      return; // eslint-disable-line no-useless-return
    }

    try {
      const subscriptionRequests: Record<string, any> | null =
        await fetchAllSubscriptionRequests(instance, accounts);
      setIsLoading(false);
      if (subscriptionRequests != null && subscriptionRequests.count > 0) {
        prepareTable(subscriptionRequests);
      } else {
        setErrorMessage('No pending subscription request has been found');
        setErrorMessageVisibility(true);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessageVisibility(true);
      setErrorMessage('Unexpected error');
      if (axios.isAxiosError(error)) {
        // Axios error
        if (error.response) {
          // The request was made and the server responded with
          // a status code that falls out of the range of 2xx
          logger.log('Axios Error: ', error.message);
          logger.log('Axios Error Detail:', error.response.statusText);
        }
      } else {
        // Any other error
        logger.log(
          'Unexpected error while fetching subscription requests: ',
          error,
        );
      }
    }
  };

  React.useEffect(() => {
    fetchSubscriptionRequests();
  }, []);

  return (
    <div className="page-body">
      <CAlert
        color="danger"
        variant="solid"
        dismissible
        visible={errorMessageVisibility}
        onClose={() => setErrorMessageVisibility(false)}>
        {errorMessage}
      </CAlert>

      {isLoading ? (
        <CSpinner className="spinner" />
      ) : (
        <div>
          {roles.includes(ROLE_ADMIN) ? (
            <div>
              <h1>Welcome to the Subscription Requests Page</h1>
              <p>
                You can find all submitted subscription requests below (if any):
              </p>
              {errorMessage === '' ? (
                <CTable
                  className="subscription-requests-table"
                  tableHeadProps={{ color: 'dark' }}
                  columns={columns}
                  items={items}
                />
              ) : null}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}
