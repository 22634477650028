import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  CCard,
  CCollapse,
  CContainer,
  CNavbar,
  CNavbarBrand,
  CNavbarNav,
  CNavbarText,
  CNavbarToggler,
  CNavItem,
  CNavLink,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilUser } from '@coreui/icons';
import { AuthContext } from '..';
import { ROLE_ADMIN } from '../utils/constants';
import { initConfig } from '../api/general';

function NavBar(): JSX.Element {
  const [visible, setVisible] = React.useState(false);
  const { account, roles } = useContext(AuthContext);

  let navbarColor = '';
  if (initConfig.env === 'local') {
    navbarColor = 'info';
  } else if (initConfig.env === 'dev') {
    navbarColor = 'warning';
  } else if (initConfig.env === 'qa') {
    navbarColor = 'danger';
  }

  let title = 'Wärtsilä API Management';
  if (initConfig.env !== 'prod') {
    title = `${title} - ${initConfig.env.toUpperCase()}`;
  }

  return (
    <CNavbar expand="lg" color={navbarColor} colorScheme="light">
      <CContainer fluid>
        <CNavbarBrand className="my-0 h1">
          <Link className="navbar-link" to="/">
            {title}
          </Link>
        </CNavbarBrand>
        <CNavbarToggler onClick={() => setVisible(!visible)} />
        <CCollapse className="navbar-collapse" visible={visible}>
          <CNavbarNav className="me-auto mb-2 mb-lg-0 navbar-content">
            <CNavItem>
              <CNavLink href="#/aad-request-helper">
                AAD Request Helper
              </CNavLink>
            </CNavItem>
            <CNavItem>
              <CNavLink href="#/email-notifications">
                Email notifications
              </CNavLink>
            </CNavItem>
            <div className="right-navbar-container">
              {roles.includes(ROLE_ADMIN) && (
                <CNavItem>
                  <CNavLink href="#/subscription-requests">
                    Admin: Subscription Requests
                  </CNavLink>
                </CNavItem>
              )}
              <CCard>
                <CIcon icon={cilUser} />
                <CNavbarText className="ms-1">{account.name}</CNavbarText>
              </CCard>
            </div>
          </CNavbarNav>
        </CCollapse>
      </CContainer>
    </CNavbar>
  );
}

export default NavBar;
