import axios from 'axios';
import { useEffect, useState } from 'react';
import { CSpinner, CAlert, CButton } from '@coreui/react';
import { useMsal } from '@azure/msal-react';
import { logger } from '../../logger';
import EmailNotificationCheckbox from './EmailNotificationCheckbox';
import './emailNotification.scss';
import {
  fetchUserData,
  fetchEmailReports,
  updateEmailReports,
} from '../../api/emailNotification';

export default function EmailNotification(): JSX.Element {
  const [activeEmailReportsData, setActiveEmailReportsData] = useState<
    Record<string, any>[]
  >([]);
  const { instance, accounts } = useMsal();
  const [isLoading, setIsLoading] = useState(true);
  const [successfulSavingVisible, setSuccessfulSavingVisible] = useState(false);

  const fetchUserEmails = async (): Promise<void> => {
    try {
      const userData: Record<string, any> | null = await fetchUserData(
        instance,
        accounts,
      );
      const activeEmailReportsIds = userData?.active_reports;
      const isManager = userData?.is_manager;
      const isSubscriber = userData?.is_subscriber;
      const isConsumer = userData?.is_consumer;
      const allReports = [];
      if (isManager) {
        const results = await fetchEmailReports(instance, accounts, 'manager');
        allReports.push(...results);
      }
      if (isSubscriber) {
        const results = await fetchEmailReports(
          instance,
          accounts,
          'subscriber',
        );
        allReports.push(...results);
      }
      if (isConsumer) {
        const results = await fetchEmailReports(instance, accounts, 'consumer');
        allReports.push(...results);
      }
      if (activeEmailReportsIds != null) {
        allReports.forEach((item) => {
          if (activeEmailReportsIds.includes(item.id)) {
            item.subscribed = true;
          } else {
            item.subscribed = false;
          }
        });
        allReports.sort((a, b) => (a.id < b.id ? -1 : 1));
      }
      setIsLoading(false);
      setActiveEmailReportsData(allReports);
    } catch (error) {
      setIsLoading(false);
      if (axios.isAxiosError(error)) {
        // Axios Error
        if (error.response) {
          // An example of error.message: Request failed with status code 404
          logger.log('Axios Error Message:', error.message);
          logger.log('Axios Error Detail:', error.response.statusText);
        }
      } else {
        // Any other error
        logger.log('Unexpected error while fetching data', error);
      }
    }
  };

  const handleChange = (id: string): void => {
    const updatedEmails = activeEmailReportsData.map((item) => {
      if (item.id === id) {
        return { ...item, subscribed: !item.subscribed };
      }
      return item;
    });
    setActiveEmailReportsData(updatedEmails);
  };

  const reportsChecklist = activeEmailReportsData.map((item) => (
    <EmailNotificationCheckbox
      key={String(item.id)}
      id={item.id}
      title={item.title}
      subscribed={item.subscribed}
      type={item.type}
      handleChange={() => handleChange(item.id)}
    />
  ));

  const handleSave = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const activeReports = activeEmailReportsData?.map((item) => {
        return {
          [item.id.toString()]: item.subscribed,
        };
      });
      const bodyContent: any = { active_reports: activeReports };
      const data = await updateEmailReports(instance, accounts, bodyContent);
      setIsLoading(false);
      if (data !== '') {
        logger.log('Reports updated successfully');
        setSuccessfulSavingVisible(true);
      }
    } catch (error) {
      setIsLoading(false);
      if (axios.isAxiosError(error)) {
        // Axios Error
        if (error.response) {
          // An example of error.message: Request failed with status code 404
          logger.log('Axios Error Message:', error.message);
          logger.log('Axios Error Detail:', error.response.statusText);
        }
      } else {
        // Any other error
        logger.log('Unexpected error while saving the reports', error);
      }
    }
  };

  useEffect(() => {
    fetchUserEmails();
  }, []);

  return (
    <div className="page-body">
      <h1 className="text-center">Unsubscribe from emails</h1>
      <CAlert
        color="success"
        variant="solid"
        dismissible
        visible={successfulSavingVisible}
        onClose={() => setSuccessfulSavingVisible(false)}>
        Email settings updated successfully.
      </CAlert>
      <div className="text-block">
        On this page, you can unsubscribe from APIM-related emails that you
        receive as an API owner/approver or as an API subscriber/consumer.
        <br />
        Please note that some emails are mandatory and you cannot unsubscribe
        from them.
      </div>
      {isLoading ? (
        <CSpinner className="spinner" />
      ) : (
        <>
          <div className="checklist">{reportsChecklist}</div>
          <div className="email-notifications-button-container">
            <CButton
              className="email-notifications-save-button"
              color="success"
              onClick={handleSave}>
              Save
            </CButton>
          </div>
        </>
      )}
    </div>
  );
}
