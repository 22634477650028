import { IPublicClientApplication, AccountInfo } from '@azure/msal-browser';
import { sendHttpRequest } from './general';

export const fetchUserData = async (
    instance: IPublicClientApplication,
    accounts: AccountInfo[],
  ): Promise<Record<string, any>| null> => {
  const response = await sendHttpRequest(
      instance,
      accounts,
      `persons/${accounts[0].localAccountId}/`,
      'GET',
);
 return response
};

export const fetchEmailReports = async (
  instance: IPublicClientApplication,
  accounts: AccountInfo[],
  recipient: string,
): Promise<Record<string, any>[]> => {
  const response = await sendHttpRequest(
    instance,
    accounts,
    `reports/?report_recipient=${recipient}`,
    'GET',
);
return response?.results
}

export const updateEmailReports = async (
    instance: IPublicClientApplication,
    accounts: AccountInfo[],
    bodyContent: any,
  ): Promise<string> => {
    const response = await sendHttpRequest(
      instance,
      accounts,
      `persons/${accounts[0].localAccountId}/`,
      'PATCH',
      bodyContent,
    );
    return response?.details;
  };
  