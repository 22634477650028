import React from 'react';

import {
  CButton,
  CForm,
  CFormInput,
} from '@coreui/react';

type formProps = {
    newConsumer: string,
  addNewConsumer: () => Promise<void>;
  handleNewConsumer: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

// Main function: New Consumer Form
function NewConsumerForm({
  newConsumer,
  addNewConsumer,
  handleNewConsumer
}: formProps): JSX.Element {


  return (
    <div className="d-flex new-consumer-app-form">
    <CForm>
    <CFormInput
        type="text"
        onChange={handleNewConsumer}
        id="new consuming app"
        label="Cannot find your consuming application in the dropdown list? Add it here."
        placeholder="Consuming application name"
        aria-describedby="new-consuming-application-box"
    />
    </CForm>  
    <CButton
    color="info" 
    disabled={newConsumer.trim().length === 0}
    className='new-consumer-save-button'
    onClick={addNewConsumer}>
        Add a new consuming application
        </CButton>
    </div> 
  );
}

export default NewConsumerForm;
